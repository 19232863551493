import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-page-body',
    template: `
      <!-- begin::Body -->
      <div
        class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop m-container m-container--responsive m-container--xxl m-page__container m-body">
        <div class="m-grid__item m-grid__item--fluid m-wrapper">
          <app-page-content [title]="title" [subTitle]="subTitle">
            <ng-content></ng-content>
          </app-page-content>
        </div>
      </div>
      <!-- end::Body -->
    `,
    styles: [
        ``,
    ]
})
export class PageBodyComponent {
    @Input() title = '';
    @Input() subTitle = '';
}
