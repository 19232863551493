import * as Raven from 'raven-js';
import {BrowserModule} from '@angular/platform-browser';
import { NgModule, ErrorHandler, Injectable } from '@angular/core';
import {AppComponent} from './core/containers/app';
import {environment} from '../environments/environment';

import {CoreModule} from './core/core.module';
import {RouterModule} from '@angular/router';
import {routes} from './routes';

import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {
  StoreRouterConnectingModule,
  RouterStateSerializer, DefaultRouterStateSerializer,
} from '@ngrx/router-store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';

import {reducers, metaReducers} from './reducers';
import {CustomRouterStateSerializer} from './shared/utils';

import {defineLocale} from 'ngx-bootstrap/chronos';
import {frLocale, deLocale} from 'ngx-bootstrap/locale';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LANG} from './webform/models';

Raven
  .config('https://a9491b06a7ba4e54849a6eb72c1c0ee8@sentry.io/1256322', {
    release: environment.version
  })
  .install();

@Injectable()
export class RavenErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    console.log(err);
    if (environment.production) {
      Raven.captureException(err);
    }
  }
}


frLocale.longDateFormat = {
  LT: 'HH:mm',
  LTS: 'HH:mm:ss',
  L: 'DD.MM.YYYY',
  LL: 'D MMMM YYYY',
  LLL: 'D MMMM YYYY HH:mm',
  LLLL: 'dddd D MMMM YYYY HH:mm'
};
deLocale.longDateFormat = {
  LT: 'HH:mm',
  LTS: 'HH:mm:ss',
  L: 'DD.MM.YYYY',
  LL: 'D MMMM YYYY',
  LLL: 'D MMMM YYYY HH:mm',
  LLLL: 'dddd D MMMM YYYY HH:mm'
};
// @ts-ignore
defineLocale(LANG, LANG === 'fr' ? frLocale : deLocale);

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, {useHash: true}),
    CoreModule.forRoot(),

    /**
     * StoreModule.forRoot is imported once in the root module, accepting a reducer
     * function or object map of reducer functions. If passed an object of
     * reducers, combineReducers will be run creating your application
     * meta-reducer. This returns all providers for an @ngrx/store
     * based application.
     */
    StoreModule.forRoot(reducers, {metaReducers}),

    /**
     * @ngrx/router-store keeps router state up-to-date in the store.
     */
    StoreRouterConnectingModule.forRoot({ serializer: DefaultRouterStateSerializer,
      /*
        They stateKey defines the name of the state used by the router-store reducer.
        This matches the key defined in the map of reducers
      */
      stateKey: 'router',
    }),

    /**
     * Store devtools instrument the store retaining past versions of state
     * and recalculating new states. This enables powerful time-travel
     * debugging.
     *
     * To use the debugger, install the Redux Devtools extension for either
     * Chrome or Firefox
     *
     * See: https://github.com/zalmoxisus/redux-devtools-extension
     */
    StoreDevtoolsModule.instrument({
      name: 'NHA Wizard Store DevTools',
      logOnly: environment.production,
    }),

    /**
     * EffectsModule.forRoot() is imported once in the root module and
     * sets up the effects class to be initialized immediately when the
     * application starts.
     *
     * See: https://github.com/ngrx/platform/blob/master/docs/effects/api.md#forroot
     */
    EffectsModule.forRoot([]),

  ],
  providers: [
    /**
     * The `RouterStateSnapshot` provided by the `Router` is a large complex structure.
     * A custom RouterStateSerializer is used to parse the `RouterStateSnapshot` provided
     * by `@ngrx/router-store` to include only the desired pieces of the snapshot.
     */
    {provide: RouterStateSerializer, useClass: CustomRouterStateSerializer},
    {provide: ErrorHandler, useClass: RavenErrorHandler}
    // { provide: LOCALE_ID, useValue: 'fr-CH' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
