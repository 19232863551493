import {Routes} from '@angular/router';
import {NotFoundPageComponent} from './core/containers/not-found-page';

export const routes: Routes = [
  {path: '', redirectTo: '/apply', pathMatch: 'full'},
  {
    path: '',
    loadChildren: () => import('./webform/webform.module').then(m => m.WebFormModule),
    data: { state: 'applications' }
  },
  {path: '**', component: NotFoundPageComponent},
];
