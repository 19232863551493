import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-page-body-full',
  template: `
    <!-- begin::Body -->
    <div
      class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-container--responsive m-container--xxl m-page__container m-body">
      <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <ng-content></ng-content>
      </div>
    </div>
    <!-- end::Body -->
  `,
  styles: [
      ``,
  ]
})
export class PageBodyFullComponent {

}
