export const FORM_KEY = 'rsscdc';
export const LANG = 'fr';
export const YES = 'Oui';
export const NO = 'Non';
export const NURSING_HOME_LOCATION_ID = '8619';

export const ADMIN_REP_TITLES = [
  {
    'id': '97',
    'label': 'Madame'
  },
  {
    'id': '98',
    'label': 'Monsieur'
  }
];
export const ADMIN_REP_OFFICIAL_MEASURES = [
  {
    'id': '99',
    'label': 'Aucune curatelle'
  },
  {
    'id': '100',
    'label': 'Candidat sous curatelle'
  },
  {
    'id': '101',
    'label': 'Demande de curatelle en cours'
  }
];
export const BENEFITS_SERVICES = [
  {
    'id': '109',
    'label': 'PC Domicile'
  },
  {
    'id': '110',
    'label': 'PC Home'
  }
];

export const CANDIDATE_TITLES = [
  {
    id: 1,
    label: 'Madame'
  },
  {
    id: 2,
    label: 'Monsieur'
  }
];

export const CANDIDATE_STATES = [
  {
    'id': '8',
    'label': 'Argovie - Aargau'
  },
  {
    'id': '9',
    'label': 'Appenzell Rhodes intérieures - Inner-Rhoden'
  },
  {
    'id': '10',
    'label': 'Berne - Bern'
  },
  {
    'id': '11',
    'label': 'Appenzell Rhodes extérieures - Ausser-Rhoden'
  },
  {
    'id': '12',
    'label': 'Bâle Campagne - Basel Land'
  },
  {
    'id': '13',
    'label': 'Genève - Genf'
  },
  {
    'id': '14',
    'label': 'Fribourg - Freiburg'
  },
  {
    'id': '15',
    'label': 'Bâle Ville - Basel Stadt'
  },
  {
    'id': '16',
    'label': 'Glaris - Glarus'
  },
  {
    'id': '17',
    'label': 'Jura'
  },
  {
    'id': '18',
    'label': 'Lucerne -Luzern'
  },
  {
    'id': '19',
    'label': 'Neuchâtel - Neuenburg'
  },
  {
    'id': '20',
    'label': 'Grisons - Graubuenden'
  },
  {
    'id': '21',
    'label': 'Nidwald - Nidwalden'
  },
  {
    'id': '22',
    'label': 'Obwald - Obwalden'
  },
  {
    'id': '23',
    'label': 'Saint-Gall - Sankt Gallen'
  },
  {
    'id': '24',
    'label': 'Schaffhouse - Schaffausen'
  },
  {
    'id': '25',
    'label': 'Soleure - Solothurn'
  },
  {
    'id': '26',
    'label': 'Thurgovie - Thurgau'
  },
  {
    'id': '27',
    'label': 'Schwyz'
  },
  {
    'id': '28',
    'label': 'Ticino - Tessin'
  },
  {
    'id': '29',
    'label': 'Uri'
  },
  {
    'id': '30',
    'label': 'Vaud - Waadt'
  },
  {
    'id': '31',
    'label': 'Valais - Vallis'
  },
  {
    'id': '32',
    'label': 'Zug'
  },
  {
    'id': '33',
    'label': 'Zurich - Zürich'
  }
];

export const CANDIDATE_STATUS = [
  {
    'id': '3',
    'label': 'Célibataire'
  },
  {
    'id': '4',
    'label': 'Marié(e)'
  },
  {
    'id': '5',
    'label': 'Divorcé(e)'
  },
  {
    'id': '6',
    'label': 'Veuf(ve)'
  },
  {
    'id': '7',
    'label': 'Séparé(e)'
  }
];


export const COMPLEMENTARY_INSURANCE_TYPES = [
  {
    'id': '102',
    'label': 'Privé'
  },
  {
    'id': '103',
    'label': 'Autre'
  },
  {
    'id': '104',
    'label': 'Demi-privé'
  }
];


export const RELATIVE1_TITLES = [
  {
    'id': '93',
    'label': 'Madame'
  },
  {
    'id': '94',
    'label': 'Monsieur'
  }
];

export const RELATIVE2_TITLES = [
  {
    'id': '91',
    'label': 'Madame'
  },
  {
    'id': '92',
    'label': 'Monsieur'
  }
];


export const SITUATION_LOCATIONS = [
  {
    'id': '79',
    'label': 'Domicile'
  },
  {
    'id': '80',
    'label': 'Hôpital'
  },
  {
    'id': '100214',
    'label': 'SEO'
  },
  {
    'id': '8619',
    'label': 'EMS'
  }
];
export const SITUATION_LIVES_WITH = [
  {
    'id': '125',
    'label': 'Seul(e)'
  },
  {
    'id': '126',
    'label': 'Avec conjoint'
  },
  {
    'id': '127',
    'label': 'Avec enfant(s)'
  },
  {
    'id': '128',
    'label': 'Animaux de compagnie'
  },
  {
    'id': '129',
    'label': 'Autre'
  }
];

export const STAY_REQUEST_TYPES = [
  {
    'id': '35',
    'label': 'Court séjour'
  },
  {
    'id': '34',
    'label': 'Long séjour'
  },
  {
    'id': '36',
    'label': 'Foyer de jour'
  },
  {
    'id': '37',
    'label': 'Unité spécialisée en démence'
  },
  {
    'id': '99880',
    'label': `SEO (séjour d'évaluation et d'orientation) Billens`
  },
];
export const STAY_REQUEST_ENTRIES = [
  {
    'id': '38',
    'label': 'immédiate'
  },
  {
    'id': '39',
    'label': '1 mois'
  },
  {
    'id': '40',
    'label': '3 mois'
  },
  {
    'id': '42',
    'label': '6 mois'
  }
];
export const STAY_REQUEST_NURSING_HOME1 = [
  {
    'id': '113',
    'label': 'Cottens – Résidence Saint-Martin',
    'stay_types': [35, 34]
  },
  {
    'id': '114',
    'label': 'Farvagny – Home du Gibloux',
    'stay_types': [34, 36, 37]
  },
  {
    'id': '115',
    'label': 'Fribourg – Villa Beausite',
    'stay_types': [35, 34]
  },
  {
    'id': '116',
    'label': 'Fribourg – Les Bonnesfontaines',
    'stay_types': [34]
  },
  {
    'id': '117',
    'label': 'Fribourg – Résidence des Chênes',
    'stay_types': [34, 37]
  },
  {
    'id': '118',
    'label': 'Fribourg – EMS La Providence',
    'stay_types': [34, 152]
  },
  {
    'id': '119',
    'label': 'Givisiez – Le Manoir',
    'stay_types': [34]
  },
  {
    'id': '120',
    'label': 'Marly – Résidence Les Epinettes',
    'stay_types': [34]
  },
  {
    'id': '121',
    'label': 'Oberried – Foyer les Peupliers (lits AOS)',
    'stay_types': [34]
  },
  {
    'id': '122',
    'label': 'Villars-sur-Glâne – Résidence Les Martinets',
    'stay_types': [35, 34, 37]
  },
  {
    'id': '124',
    'label': 'Villars-sur-Glâne – Home médicalisé de la Sarine',
    'stay_types': [34, 35, 36]
  },
  {
    'id': '100893',
    'label': 'Billens',
    'stay_types': [99880]
  }
];
export const STAY_REQUEST_NURSING_HOME2 = [
  {
    'id': '55',
    'label': 'Cottens – Résidence Saint-Martin',
    'stay_types': [35, 34]
  },
  {
    'id': '56',
    'label': 'Farvagny – Home du Gibloux',
    'stay_types': [34, 36, 37]
  },
  {
    'id': '57',
    'label': 'Fribourg – Villa Beausite',
    'stay_types': [35, 34]
  },
  {
    'id': '58',
    'label': 'Fribourg – Les Bonnesfontaines',
    'stay_types': [34]
  },
  {
    'id': '59',
    'label': 'Fribourg – Résidence des Chênes',
    'stay_types': [34, 37]
  },
  {
    'id': '60',
    'label': 'Fribourg – EMS La Providence',
    'stay_types': [34, 152]
  },
  {
    'id': '61',
    'label': 'Givisiez – Le Manoir',
    'stay_types': [34]
  },
  {
    'id': '62',
    'label': 'Marly – Résidence Les Epinettes',
    'stay_types': [34]
  },
  {
    'id': '63',
    'label': 'Oberried – Foyer les Peupliers (lits AOS)',
    'stay_types': [34]
  },
  {
    'id': '64',
    'label': 'Villars-sur-Glâne – Résidence Les Martinets',
    'stay_types': [35, 34, 37]
  },
  {
    'id': '66',
    'label': 'Villars-sur-Glâne – Home médicalisé de la Sarine',
    'stay_types': [34, 35, 36]
  },
  {
    'id': '100894',
    'label': 'Billens',
    'stay_types': [99880]
  }
];


export const THERAPEUTIC_REP_TITLES = [
  {
    'id': '95',
    'label': 'Madame'
  },
  {
    'id': '96',
    'label': 'Monsieur'
  }
];
