import { Component } from '@angular/core';


@Component({
    selector: 'm-spinner',
    template: `
        <div class="m-loader m-loader--brand"></div>
    `,
})
export class SpinnerComponent {
}
