import { Component } from '@angular/core';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-footer',
    template: `
      <!--<footer class="m-grid__item m-footer ">-->
        <!--<div class="m-container m-container&#45;&#45;responsive m-container&#45;&#45;xxl m-container&#45;&#45;full-height m-page__container">-->
          <!--<div class="m-footer__wrapper">-->
            <!--<div class="m-stack m-stack&#45;&#45;flex-tablet-and-mobile m-stack&#45;&#45;ver m-stack&#45;&#45;desktop">-->
              <!--<div class="m-stack__item m-stack__item&#45;&#45;left m-stack__item&#45;&#45;middle m-stack__item&#45;&#45;last">-->
								<!--<span class="m-footer__copyright">-->
									<!--2018 &copy; By WeDo Sàrl-->
									<!--<a href="https://www.wedo.swiss" class="m-link">-->
										<!--wedo.swiss-->
									<!--</a>-->
								<!--</span>-->
              <!--</div>-->
              <!--<div class="m-stack__item m-stack__item&#45;&#45;right m-stack__item&#45;&#45;middle m-stack__item&#45;&#45;first">-->
                <!--<span class="m-footer__copyright">-->
									<!--{{version}}-->
								<!--</span>-->
              <!--</div>-->
            <!--</div>-->
          <!--</div>-->
        <!--</div>-->
      <!--</footer>-->
    `
})
export class FooterComponent {
  version: string = environment.version;
}
