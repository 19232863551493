import {Component, Input, Output, EventEmitter, forwardRef, HostBinding, HostListener} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'm-checkbox',
  template: `    
    
      <input type="checkbox" class="switch-input" [value]="value" [attr.checked]="value ? '' : null" [attr.aria-label]="label">
      {{label}}
      <span></span>
    
  `,
  styles: [
      ``,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ]
})
export class CheckboxComponent implements ControlValueAccessor {
  @HostBinding('class.m-checkbox')
  @HostListener('click') onClick() {
    this.switch();
  }

  @Input() label = 'switch';
  @Input('value') _value = false;
  onChange: any = () => {
  };
  onTouched: any = () => {
  };

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  constructor() {
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    console.log(value);
    if (value) {
      this.value = value;
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  switch() {
    this.value = !this.value;
  }
}
