import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'app-not-found-page',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <app-page-content title="404: Not Found">
            <p i18n>Il semble que cette page n'existe pas encore.</p>

            <button routerLink="/" class="btn btn-primary" i18n>Retour à l'accueil</button>
        </app-page-content>
  `,
    styles: [
        `
    :host {
      text-align: center;
    }
  `,
    ],
})
export class NotFoundPageComponent { }
