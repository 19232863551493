import {Component} from '@angular/core';

@Component({
  selector: 'app-header',
  template: `
    <header id="m_header" class="m-grid__item		m-header " m-minimize="minimize" m-minimize-offset="200"
            m-minimize-mobile-offset="200">
      <div class="m-header__top">
        <div class="m-container m-container--responsive m-container--xxl m-container--full-height m-page__container">
          <div class="m-stack m-stack--ver m-stack--desktop">
            <!-- begin::Brand -->
            <div class="m-stack__item m-brand">
              <div class="m-stack m-stack--ver m-stack--general m-stack--inline">
                <div class="m-stack__item m-stack__item--middle m-brand__logo">
                  <a routerLink="/" class="m-brand__logo-wrapper">
                    <img alt="" height="60" src="assets/logo.png"/>
                  </a>
                </div>
                <div class="m-stack__item m-stack__item--middle m-brand__tools">
                  <!-- begin::Responsive Header Menu Toggler-->
                  <a id="m_aside_header_menu_mobile_toggle" href="javascript:;"
                     class="m-brand__icon m-brand__toggler m--visible-tablet-and-mobile-inline-block">
                    <span></span>
                  </a>
                  <!-- end::Responsive Header Menu Toggler-->
                  <!-- begin::Topbar Toggler-->
                  <a id="m_aside_header_topbar_mobile_toggle" href="javascript:;"
                     class="m-brand__icon m--visible-tablet-and-mobile-inline-block">
                    <i class="flaticon-more"></i>
                  </a>
                  <!--end::Topbar Toggler-->
                </div>
              </div>
            </div>
            <!-- end::Brand -->
            <!-- begin::Topbar -->
            <div class="m-stack__item m-stack__item--fluid m-header-head" id="m_header_nav">
              <div id="m_header_topbar" class="m-topbar  m-stack m-stack--ver m-stack--general">
              </div>
            </div>
            <!-- end::Topbar -->
          </div>
        </div>
      </div>
      <div class="m-header__bottom">

      </div>
    </header>
  `
})
export class HeaderComponent {

}
