import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-page-content',
    template: `
      <div class="m-content">
        <!-- BEGIN: Subheader -->
        <div class="m-subheader" *ngIf="title">
          <div class="d-flex align-items-center">
            <div class="mr-auto">
              <h3 class="m-subheader__title" ngClass="{'m-subheader__title--separator': sutTitle}">
                {{title}}
              </h3>
              <div class="m-subheader__breadcrumbs m-nav m-nav--inline">
                {{subTitle}}
              </div>
            </div>
          </div>
        </div>
        <!-- END: Subheader -->
        <div class="m-content">
          <ng-content></ng-content>
        </div>
        <br>
        <br>
      </div>
    `,
    styles: [
        ``,
    ]
})
export class PageContentComponent {
    @Input() title = '';
    @Input() subTitle = '';
}
