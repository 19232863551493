import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'body',
  template: `

    <!-- begin::Page loader -->
    <div class="m-page-loader m-page-loader--non-block" style="margin-left: -80px; margin-top: -20px;" *ngIf="loading">
      <div class="m-blockui">
			<span>
        <ng-container i18n>Veuillez patienter...</ng-container>
			</span>
        <span>
				<m-spinner></m-spinner>
			</span>
      </div>
    </div>
    <!-- end::Page loader -->

    <div class="m-grid m-grid--hor m-grid--root m-page">
      <app-header></app-header>

      <router-outlet></router-outlet>

      <app-footer></app-footer>
    </div>
  `,
})
export class AppComponent implements OnInit {
  loading: boolean;

  // @HostBinding('class.m-brand--minimize')
  // @HostBinding('class.m-aside-left--minimize')
  // showSideNav = true;

  constructor() {

  }

  ngOnInit(): void {

  }

  getState(outlet) {
    return outlet.activatedRouteData.state;
  }
}
