import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'm-portlet-title',
    template: `
        <div class="m-portlet__head-title">
            <h3 class="m-portlet__head-text">
                <ng-content></ng-content>
            </h3>
        </div>
    `
})
export class PortletTitleComponent {
    @HostBinding('class.m-portlet__head-caption')
    hasClass = true;
}

@Component({
    selector: 'm-portlet-tools',
    template: `
        <ng-content></ng-content>
    `
})
export class PortletToolsComponent {
    @HostBinding('class.m-portlet__head-tools')
    hasClass = true;
}

@Component({
    selector: 'm-portlet-head',
    template: `
        <div class="m-portlet__head">
            <ng-content select="m-portlet-title"></ng-content>
            <ng-content select="m-portlet-tools"></ng-content>
        </div>
    `,
})
export class PortletHeadComponent {
}

@Component({
    selector: 'm-portlet-footer',
    template: `
        <div class="m-portlet__foot">
            <ng-content></ng-content>
        </div>
    `,
})
export class PortletFooterComponent {
}

@Component({
    selector: 'm-portlet-content',
    template: `
        <div class="m-portlet__body">
            <ng-content></ng-content>
        </div>
    `,
})
export class PortletContentComponent {
}

@Component({
    selector: 'm-portlet',
    template: `
        <div class="m-portlet">
            <ng-content select="m-portlet-head"></ng-content>

            <ng-content select="m-portlet-content"></ng-content>

            <ng-content select="m-portlet-footer"></ng-content>
        </div>
    `,
})
export class PortletComponent {

}
