import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {NotFoundPageComponent} from './containers/not-found-page';
import {NavItemComponent} from './components/nav-item';
import {SidenavComponent} from './components/sidenav';
import {
  PortletComponent, PortletContentComponent,
  PortletFooterComponent,
  PortletHeadComponent,
  PortletTitleComponent,
  PortletToolsComponent
} from './components/portlet';
import {FooterComponent} from './components/footer';
import {SpinnerComponent} from './components/spinner';
import {AppAlertComponent} from './components/alert';
import {AppComponent} from './containers/app';
import {HeaderComponent} from './components/header';
import {RouterModule} from '@angular/router';
import {PageContentComponent} from './components/page-content';
import {PageBodyComponent} from './components/page-body';
import {PageBodyFullComponent} from './components/page-body-full';
import {CheckboxComponent} from './components/checkbox';

export const COMPONENTS = [
  AppComponent,
  NotFoundPageComponent,
  HeaderComponent,
  NavItemComponent,
  SidenavComponent,
  FooterComponent,
  PageBodyComponent,
  PageBodyFullComponent,
  PageContentComponent,
  PortletComponent,
  PortletHeadComponent,
  PortletTitleComponent,
  PortletToolsComponent,
  PortletFooterComponent,
  PortletContentComponent,
  SpinnerComponent,
  AppAlertComponent,
  CheckboxComponent
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
        ngModule: CoreModule,
        providers: [],
    };
}
}
